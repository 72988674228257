export default {
  methods: {
    async suggestAddress (address) {
      const response = await fetch(`${this.API_URL}/suggest?lang=cs&limit=5&type=regional.address&apikey=${this.API_KEY}&query=${address}`)
      const data = await response.json()
      return data.items.map(item => {
        const regionalCountry = item.regionalStructure.find(i => i.type === 'regional.country') ?? {}
        const regionalRegions = item.regionalStructure.filter(i => i.type === 'regional.region') ?? []
        let location = {}
        let region = {}

        if (regionalRegions.length > 0) {
          location = regionalRegions.find(r => r.name.toLowerCase().includes('okres'))
          region = regionalRegions.find(r => r.name.toLowerCase().includes('kraj'))
        }

        return {
          name: item?.name ?? '',
          locationDetails: item?.location ?? '',
          country: regionalCountry?.name ?? '',
          location: location?.name ?? '',
          region: region?.name ?? '',
          data: item ?? {}
        }
      })
    },
    isValidGeo (geo) {
      const geoPattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/
      return geoPattern.test(geo)
    }
  }
}
